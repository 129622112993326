import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import ImageContainer from "../components/Image/ImageContainer";
import TextContainer from "../components/Text/TextContainer";
import {getCollectionUrl} from "../utils";
import MemberSlider from "../components/MemberSlider";
import ParagraphPlaceholder from "./Placeholder/ParagraphPlaceholder";
class SectionsPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: {},
            loading: true,
            hasSections: false
        }
    }
    getContent(){
        fetch(getCollectionUrl('pages'), {
            mode: 'cors',
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                filter: {_id:this.props._id},
                limit: 1,
                skip: 0,
                populate: 1, // resolve linked collection items
            })
        })
            .then(response => response.json())
            .then(data => {
                var hasSections=false;
                if(data.entries[0].sections==null){
                    hasSections=false;
                }else{
                    if(data.entries[0].sections.length>0){
                        hasSections=true;
                    }else{
                        hasSections=false;
                    }
                }
                this.setState({
                    data: data.entries[0],
                    loading: false,
                    hasSections: hasSections
                });
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }
    componentDidMount() {
        this.getContent();
    }
    renderSection=function renderSection(data,alignment,placeholder){
        const classes_1='pt-0 pt-md-5 pb-5';
        return <article
            key={data.key}
            data-aos={data.aos}
            data-aos-offset="200"
            className={alignment==='right' ? ('bg-dark text-white ' + classes_1):(classes_1)}
        >
            <div className={'container'}>
                <div className={'row'}>
                    {data.imagelist &&
                        <React.Fragment>
                            <ImageContainer arrows={true} data={data} alignment={alignment} placeholder={placeholder}/>
                            <TextContainer  data={data} alignment={alignment} fullwidth={false} placeholder={placeholder}/>
                        </React.Fragment>
                    }
                    {data.imagelist===false &&
                        <TextContainer data={data} alignment={alignment} fullwidth={true} placeholder={placeholder}/>
                    }
                </div>
            </div>
        </article>;
    }
    Placeholder=function Placeholder(alignment){
        return <React.Fragment>
            {this.renderSection({
                imagelist:true
            },alignment,true)}
        </React.Fragment>
    }

    SectionLayout=function SectionLayout(props) {
        let alignment='';
        const idx = props.idx;
        console.info('video',props.section.value.video);
        let data={
            key:idx,
            aos:props.section.value.aos,
            image: null,
            video: ( typeof props.section.value.video !== 'undefined' && props.section.value.video !== null ? props.section.value.video : false),
            imagelist:      (props.section.value.image==null || props.section.value.image.length===0 ? false : props.section.value.image),
            placeholder:    (props.section.value.image==null || props.section.value.image.length===0  ? false : props.section.value.image[0].styles[3].path),
            alt:            (props.section.value.image==null || props.section.value.image.length===0  ? false : props.section.value.image[0].meta.title),
            headline: props.section.value.headline,
            text: props.section.value.text,
            linktext: props.section.value.linktext,
            link: props.section.value.link
        }
        alignment = (idx + 1) % 2 === 0 ? 'left' : 'right';

        return <React.Fragment>
            {this.renderSection(data,alignment,this.props.placeholder)}
        </React.Fragment>
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>{this.state.data.metatitle}</title>
                    <meta name="description" content={this.state.data.metadescription}/>
                </Helmet>
                {/* End Page SEO Content */}
                {/*Section Page*/}
                {this.state.loading && this.props.pagetype !== 'normal' &&
                    <React.Fragment>
                        <div className="bg-dark text-white">
                            <div className="container pt-lg-5 border-white">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h1 aria-hidden="true"
                                            className="placeholder-glow pt-4 pt-lg-4 text-white text-uppercase fz-80 pb-0 mb-1">
                                            <span className="placeholder col-7 col-xl-3 bg-white"></span>
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.Placeholder('right')}
                        {this.Placeholder('left')}
                        {this.Placeholder('right')}
                    </React.Fragment>
                }
                {(this.state.loading===false && this.props.pagetype !== 'normal') &&
                    <div className={'bg-dark text-white'}>
                        <div className={'container pt-lg-5 border-white'}>
                            <div className={'row'}>
                                <div className={'col-lg-12'}><h1 className="pt-4 pt-lg-4 text-uppercase fz-80 pb-0 mb-1">
                                    {this.state.data.title}
                                </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {this.state.data.sections?.map((section, idx) => (
                    <React.Fragment key={idx}>
                        {this.SectionLayout({
                            idx: idx,
                            section: section
                        })}
                    </React.Fragment>
                ))}
                {/*Normal Page*/}
                {this.state.loading && (this.props.pagetype === 'normal') &&
                    <React.Fragment>

                        {/*Freundeskreis*/}
                        {/*{this.props._id==='01dd5e05313039f42d0001a6' &&*/}
                        {/*    <React.Fragment>*/}
                        {/*    {this.Placeholder('right')}*/}
                        {/*    </React.Fragment>*/}
                        {/*}*/}
                        <div className="bg-white text-dark">
                            <div className="container pt-lg-5 border-white">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <h1 aria-hidden="true"
                                            className="placeholder-glow pt-4 pt-lg-4 text-dark text-uppercase fz-80 pb-0 mb-1">
                                            <span className="placeholder col-7 col-xl-3 bg-dark"></span>
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'container pt-5 pb-5 htmltext lead hyphenation'}>
                            <p aria-hidden="true" className={'placeholder-glow '}>
                                <ParagraphPlaceholder/>
                                <ParagraphPlaceholder/>
                                <ParagraphPlaceholder/>
                                <ParagraphPlaceholder/>
                            </p>
                        </div>
                    </React.Fragment>
                }
                {(this.state.loading===false && this.props.pagetype === 'normal' && this.state.hasSections===false) &&
                    <div className={'text-dark'}>
                        <div className={'container pt-lg-5 border-white'}>
                            <div className={'row'}>
                                <div className={'col-lg-12'}>
                                    <h1 className="pt-4 pt-lg-4 text-uppercase fz-80 pb-0 mb-1">
                                    {this.state.data.title}
                                </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div className={'container pt-5 pb-5 htmltext lead hyphenation'} dangerouslySetInnerHTML={{__html: this.state.data.html}}></div>
                {this.props._id==='6c9975e861623207e30002a7' &&
                    <section data-aos="fade-zoom-in" data-aos-offset="200">
                        <div  id={'artists'} className={'container mb-5'}>
                            <MemberSlider
                                collection={'members'}
                                type={'artists'}
                                head={'Lebens- und Arbeitsraum'}
                                subhead={'Gruppen & Künstler*innen in der EISFABRIK'}
                                teaser={'Die EISFABRIK ist ebenso Spiel- und Präsentationsstätte wie Lebens- und Arbeitsraum. – Tanz, Theater, Musik, Fotografie und Künste haben hier in den den vier großen Hallen und Sälen, den Ateliers, Übungsräumen und Musikstudios ihren Ort.'}
                            />
                        </div>
                    </section>
                }
                {this.props._id==='357f23d36131333c9e000075' &&
                    <section data-aos="fade-zoom-in" data-aos-offset="200">
                        <div  id={'artists'} className={'container mb-5'}>
                            <MemberSlider
                                collection={'team'}
                                type={'artists'}
                                head={'Mitglieder'}
                                subhead={false}
                                teaser={false}
                            />
                        </div>
                    </section>
                }
            </>
        );
    }
}

export default SectionsPage;

