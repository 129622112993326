import React, {Component} from 'react';
import SectionsPage from "../../components/SectionsPage";

class Eisfabrik extends Component {

    render() {
        return (
            <>
                <SectionsPage pagetype={'sections'} _id={'620f3d28373466bd6900008f'} />
            </>
        );
    }
}

export default Eisfabrik;

