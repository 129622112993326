import React, { useState } from 'react';
import {Link,NavLink,Outlet,useLocation } from "react-router-dom";
import {Navbar,Nav,Container,Offcanvas,NavDropdown} from 'react-bootstrap';
const Navitems=[
    {
        title:'Freundeskreis',
        link:'/freundeskreis'
    },
    {
        title:'Kontakt & Anreise',
        link:'/kontakt'
    }
]
const Mainnavigation = (props) => {
    const [isOpen, setOpen] = useState(false);
    const { pathname } = useLocation();
    return (
        <>
            <Navbar
                expanded={isOpen}
                expand={'lg'}
                bg="dark"
                fixed={false}
                className="bg-dark navbar-dark pt-lg-3"
            >
                <Container className={'ps-lg-0 pb-2 '}>
                    <Link className={"navbar-brand"} to="/">
                        <img src="/static/images/eisfabrik_nav_logo.svg" alt="EISFABRIK" width="150" height="75" />
                    </Link>
                    <Navbar.Toggle
                        aria-controls="offcanvasNavbar"
                        aria-expanded={(isOpen ? 'true' : 'false')}
                        onClick={() => setOpen(isOpen ? false : "expanded")}
                    />
                    <Navbar.Offcanvas
                        id="offcanvasNavbar"
                        placement="end"
                    >
                        <Offcanvas.Header
                            closeButton
                            className="justify-content-end"
                            onClick={() => setOpen(false)}
                        ></Offcanvas.Header>
                        <Offcanvas.Body>
                            <Nav className=" ps-0 me-auto mb-2 mb-lg-0">
                                <NavLink
                                    className={"nav-link main-nav-link has-secondary-font text-white fz-24 d-lg-none"}
                                    to={'/'}
                                    onClick={() => setOpen(false)}
                                >
                                    Home / Programm
                                </NavLink>
                                <NavDropdown
                                    title="EISFABRIK"
                                    id="navigation-dropdown"
                                    menuVariant="dark"
                                    className={"bg-dark text-white main-nav-link has-secondary-font text-white fz-24" + ((pathname==='/eisfabrik-hannover' || pathname==='/eisfabrik-geschichte') ? ' parent active ': ' parent ')}
                                >
                                    <NavDropdown.Item onClick={() => setOpen(false)} to="/eisfabrik-hannover" as={Link} className="nav-link dropdown-item-sub fz-18">Kunst Theater Tanz</NavDropdown.Item>
                                    <NavDropdown.Item onClick={() => setOpen(false)} to="/eisfabrik-kuenstlerinnen" as={Link} className="nav-link dropdown-item-sub fz-18">Künstler*innen</NavDropdown.Item>
                                    <NavDropdown.Item onClick={() => setOpen(false)} to="/eisfabrik-geschichte" as={Link} className="nav-link dropdown-item-sub fz-18">Geschichte der EISFABRIK</NavDropdown.Item>
                                    <NavDropdown.Item onClick={() => setOpen(false)} to="/eisfabrik-team" as={Link} className="nav-link dropdown-item-sub fz-18">Team der EISFABRIK</NavDropdown.Item>
                                </NavDropdown>
                                {Navitems?.map((entry, idx) => (
                                    <NavLink
                                        className={"nav-link main-nav-link has-secondary-font text-white fz-24"}
                                        to={entry.link}
                                        onClick={() => setOpen(false)}
                                        key={idx}
                                    >
                                        {entry.title}
                                    </NavLink>
                                ))}
                            </Nav>
                            <ul className="navbar-nav d-none d-lg-flex ">
                                <li className="nav-item">
                                    <a className={'nav-link '} href="https://www.facebook.com/eisfabrikhannover" ><img alt={'Facebook'} width={'24'} height={'24'} className={'img-fluid align-self-center'} src={'/static/images/facebook_white.svg'} /></a>
                                </li>
                                <li className="nav-item">
                                    <a className={'nav-link '} href="https://www.instagram.com/eisfabrik_kultur/?hl=de" ><img alt={'Instagram'} width={'24'} height={'24'} className={'img-fluid align-self-center'} src={'/static/images/instagram_white.svg'} /></a>
                                </li>
                            </ul>
                            <div className={'mt-4 pt-4 pb-4 text-white d-flex d-lg-none border-white border-3 text-center'}>
                                <div className={'row'}>
                                    <div className={'col-3 offset-1'}>
                                        <a  href="http://gafeisfabrik.de"><img width={'90'} height={'90'} className={'img-fluid'} alt={'Logo GAF – Galerie für Fotografie'}  src={'/static/images/gaf_logo.svg'} /></a></div>
                                    <div className={'col-4'}>
                                        <a  href="https://commedia-futura.de/spielplan.html"><img width={'180'} height={'100'} className={'img-fluid'} alt={'Logo Commedia Futura'} src={'/static/images/commedia_futura.svg'} /></a>
                                    </div>
                                    <div className={'col-3'}>
                                        <NavLink

                                            to={'/kunst-in-der-weissen-halle'}
                                            onClick={() => setOpen(false)}
                                        >
                                            <img width={'90'} height={'90'} className={'img-fluid'} alt={'Schriftzug: Kunst in der Weissen Halle'} src={'/static/images/weisse_halle.svg'} />
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
                </Navbar>
            <Outlet />
        </>
    );
};

export default Mainnavigation;