import React, {Component} from 'react';
import SectionsPage from "../../components/SectionsPage";

class Eisfabrik extends Component {
    render() {
        return (
            <>
                <SectionsPage pagetype={'sections'} _id={'6c9975e861623207e30002a7'} />
            </>
        );
    }
}

export default Eisfabrik;

