import React, {Component} from 'react';
import SectionsPage from "../../components/SectionsPage";

class Team extends Component {
    render() {
        return (
            <>
                <SectionsPage pagetype={'sections'} _id={'357f23d36131333c9e000075'} />
            </>
        );
    }
}

export default Team;

