import React, {Component} from 'react';
import SectionsPage from "../../components/SectionsPage";

class Impressum extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {
    }


    render() {
        return (
            <>
                <SectionsPage pagetype={'normal'} _id={'6cc0bc8138363657d00002ba'} />
            </>
        );
    }
}

export default Impressum;

