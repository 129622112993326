import React from "react"
import { LazyLoadImage } from 'react-lazy-load-image-component';

function renderImage(entry){
    if(entry.image!==null){
        if(entry.image.styles[0].path!==''){
            return <LazyLoadImage
                className={'img-fluid rounded-circle'}
                alt={entry.title}
                height={140}
                width={140}
                src={process.env.REACT_APP_API_URL +  entry.image.styles[0].path} // use normal <img> attributes as props
            />
        }else{
            return null;
        }
    }else{
        return null;
    }
}

function Setcard(props) {
    const entry=props.person;
    return (
            <div className={'row mb-5 pb-5'}>
                <div className={'col-12 d-lg-block col-lg-4 col-xl-2 text-center'}>
                    <div className={'mt-auto mb-auto'}>
                        {renderImage(entry)}
                    </div>
                </div>
                <div className={'pt-4 pt-lg-0 col-12 col-lg-8 text-center text-lg-start'}>
                    <h2 className="text-uppercase fz-45 has-secondary-font pb-0 mb-0">{entry.title}</h2>
                    <p className={'d-block text-uppercase fz-45 has-secondary-font fw-normal pb-0 mb-0'}>{entry.subtitle}</p>
                </div>
                <div className={'col-12 offset-lg-4 offset-xl-2 col-lg-8 col-xl-8 pt-3'}>
                    <p>{entry.description}</p>
                    <div className={' text-center text-lg-start'}>
                    {renderLink(entry)}
                    <p className={'mt-3 mb-0'}>
                        {entry.email &&
                            <a className={'text-dark'} href={'mailto:'+entry.email}>{entry.email}</a>
                        }
                        {(entry.email && entry.phone) && <span> | </span>}
                        {entry.phone &&
                            <span>{entry.phone}</span>
                        }
                    </p>
                    </div>
                </div>
                </div>
    )
}
function renderLink(entry){
    if(entry.link!==null){
        return <a className={'btn btn-lg-lg btn-dark has-secondary-font fw-bold fz-20 text-uppercase'} href={entry.link} title={entry.link}>{entry.link.replace(/((http||https):\/\/)/gm,'')}</a>;
    }else{
        return null;
    }
}
export default function SliderList(prop) {
    const [loaded, setLoaded] = React.useState([])
    const [currentSlide, setCurrentSlide] = React.useState(0)
    React.useEffect(() => {
        const new_loaded = [...loaded]
        new_loaded[currentSlide] = true
        setLoaded(new_loaded)
    }, [currentSlide])
    console.log(loaded[0]);
    return (
        <>
            {loaded.length===0 &&
                <React.Fragment>x</React.Fragment>
            }
            {loaded.length>0 &&
                <>
                    {prop.memberslist?.map((entry, idx) => (
                        <Setcard key={idx} person={entry}/>
                    ))}
               </>
            }

        </>
    )
}


