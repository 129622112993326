import React, { Component } from 'react';
import {getCollectionUrl} from "../utils";
import ImageContainer from "./Image/ImageContainer";

class Highlights extends Component {
    constructor(props){
        super(props);
        this.state = {
            highlights: []
        }
    }

    renderSlider(entry){
        let sliderdata = {
            image: null,
            imagelist: (entry.image == null ? false : [entry.image]),
            placeholder: (entry.image == null ? false : entry.image.styles[3].path),
            alt: (entry.image == null ? false : entry.image.meta.title),
            width: 550,
            height: 550,
            video: false
        }
        return sliderdata;
    }
    render() {
        return (
              <>
                  {this.props.highlights?.map((entry, idx) => (
                          <div className={'row'} key={idx}>
                              <div className={'col-md-3 mb-3'}>
                                  <div className="ratio ratio-1x1">
                                      <ImageContainer data={this.renderSlider(entry)} lazyloading={false} arrows={false}
                                                       alignment={'left'} placeholder={false}/>
                                  </div>
                              </div>
                              <div className={'col-md-9 d-flex align-items-center'}>
                                  <div className={'mt-auto mb-auto'}>
                                      <h2 className={'fz-24 text-uppercase'}>
                                          {entry.title}
                                      </h2>
                                      <p className={'lead hyphenation'}>{entry.excerpt}</p>
                                      <a className={'has-secondary-font fw-bold text-uppercase btn btn-dark'}
                                         href={entry.link}>{entry.linktext}</a>
                                  </div>
                              </div>
                          </div>
                  ))}
              </>
        );
    }
}

export default Highlights;

