import React, { Component } from 'react';
import {NavLink} from "react-router-dom";

class TextContainer extends Component {
    constructor(props){
        super(props);
        this.state = {
        }
    }
    render() {
        const fullwidth_class=(this.props.fullwidth ? ' col-md-12 ' : ' col-md-6 ');
        const classes_1 = fullwidth_class + ' d-flex align-items-start flex-column pt-5 pb-5 hyphenation';
        const classes_2='lead mt-auto mb-auto';
        //Interner / externer Link //REACT_APP_HOST
        const renderLink=function renderLink(props){
            const classes_3='mt-3 text-uppercase btn border border-3 text-uppercase has-secondary-font fw-bold fz-20';
            const host = process.env.REACT_APP_HOST.replace("https://", "");
            function isExternalLink() {
                const tmp = document.createElement('a');
                tmp.href = props.data.link;
                return tmp.host !== host;
            }
            if(isExternalLink()){
                return <React.Fragment
                >
                    <a
                        className={props.alignment==='left' ? ('btn-dark ' + classes_3):('btn-dark ' + classes_3)}
                        href={props.data.link}
                    >
                        {props.data.linktext}
                    </a>
                </React.Fragment>;
            }else{
                return <React.Fragment
                >
                    <NavLink
                        className={props.alignment==='left' ? ('btn-dark ' + classes_3):('btn-dark ' + classes_3)}
                        to={(props.data.link.replace("https://" + host, ""))}
                    >
                        {props.data.linktext}
                    </NavLink>
                </React.Fragment>;
            }
        }
        const padding_end=(this.props.fullwidth ? ' pe-md-0 ' : ' pe-md-5 ');
        const margin_start=(this.props.fullwidth ? ' ms-md-0  ' : ' ms-md-4 ms-lg-5  ');
        return (
        <React.Fragment>
            <div className={this.props.alignment==='right' ? (padding_end + classes_1):(classes_1)}>
                {this.props.placeholder ? (
                    <p aria-hidden="true"
                       className={this.props.alignment==='left' ? ('col-12 placeholder-glow ms-md-4 ms-lg-5 ' + classes_2):('col-12 placeholder-glow ' + classes_2)}>
                        <span className="placeholder col-12"></span>
                        <span className="placeholder col-12"></span>
                        <span className="placeholder col-12"></span>
                        <span className="placeholder col-12"></span>
                        <span className="placeholder col-7"></span>
                    </p>
                ):(
                    <React.Fragment>
                    <p className={this.props.alignment==='left' ? (margin_start + classes_2):(classes_2)}>
                        {this.props.data.headline &&
                            <span className={'d-block h2 text-uppercase mb-4'}>{this.props.data.headline} <br /></span>
                        }
                        {this.props.data.text}<br />
                        {this.props.data.link ? (
                            renderLink(this.props)
                        ):(<React.Fragment></React.Fragment>)}
                    </p>
                    </React.Fragment>
                )}
            </div>
        </React.Fragment>
        )}
}

export default TextContainer;