import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import ScrollIntoView from 'react-scroll-into-view'
import {getDatesUrl} from "../../utils";
import ImageContainer from "../../components/Image/ImageContainer";
import PartnerLogos from "../../components/Image/PartnerLogos";
import {NavLink} from "react-router-dom";
import Highlights from "../../components/Highlights";

class HomeDefault extends Component {

    constructor(props) {
        super(props);
        this.state = {
            eventlist: [],
            allevents: [],
            highlights: [],
            image: null,
            total_chunks: 0,
            current_chunks: 0,
            chunkSize: 20,
            loading: true
        }
        this.renderShowmore.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    getData() {
        fetch(getDatesUrl(), {
            mode: 'cors',
        })
            .then(response => response.json())
            .then(data => {
                const chunkSize = this.state.chunkSize;
                const result = [];
                for (let i = 0; i < data.dates.length; i += chunkSize) {
                    const chunk = data.dates.slice(i, i + chunkSize);
                    result[i] = chunk;
                }
                this.setState({
                    image: data.slider.image,
                    highlights: data.highlights,
                    allevents: result,
                    total_chunks: Object.keys(result).length,
                    current_chunks: 0,
                    eventlist: result[0],
                    loading: false
                });
                // this.setState({ allevents: result});
                // this.setState({ total_chunks: Object.keys(result).length});
                // this.setState({ current_chunks: 0});
                // this.setState({ eventlist: result[0] });
                // this.setState({ loading: false});
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    componentDidMount() {
        this.getData();
    }

    renderTime = function renderTime(time) {
        if (time !== null)
            return <>{time} Uhr</>;
        return null;
    }
    //Interner / externer Link //REACT_APP_HOST
    checkInternalLink(link){
        const host = process.env.REACT_APP_HOST.replace("https://", "");
        function isExternalLink() {
            const tmp = document.createElement('a');
            tmp.href = link;
            return tmp.host !== host;
        }
        if(isExternalLink()){
            return false
        }else{
            return true
        }
    }
    renderShowmore = function renderShowmore(show) {
        if (show)
            return <div className={'text-center mt-3  w-100 d-grid gap-2'}>
                <button className={'btn btn-lg btn-dark text-uppercase has-secondary-font fw-bold'}
                        onClick={this.handleClick}>+ Weitere anzeigen
                </button>
            </div>;
        return null;
    }

    handleClick(param) {
        this.setState((state) => {
            if (state.current_chunks < state.total_chunks) {
                const pusharray = state.eventlist.concat(state.allevents[(state.current_chunks + 1) * this.state.chunkSize]);
                return {
                    current_chunks: state.current_chunks + 1,
                    eventlist: pusharray
                }
            }
            return null;
        });
    }

    render() {
        let sliderdata = {
            image: null,
            imagelist: (this.state.image == null || this.state.image.length === 0 ? false : this.state.image),
            placeholder: (this.state.image == null || this.state.image.length === 0 ? false : this.state.image[0].styles[3].path),
            alt: (this.state.image == null || this.state.image.length === 0 ? false : this.state.image[0].meta.title),
            video: false
        }
        return (
            <div className="">
                <Helmet>
                    <title>Eisfabrik Hannover – Theater, Tanz, Musik, bildende Kunst und Fotografie</title>
                    <meta name="description" content={'Die EISFABRIK ist ein Zentrum der Künste – Theater, Tanz, Musik, bildende Kunst und Fotografie haben hier ihren Ort. Entdecke das aktuelle Programm.'}/>
                </Helmet>
                {/* End Page SEO Content */}
                <div className={'bg-dark text-white'}>
                    <div className={'container'}>
                        <div className={'row'}>
                            <div className={'order-md-last col-md-6 col-xl-5 ps-0 pe-0 pb-lg-5'}>
                                <div className="ratio ratio-1x1">
                                    {this.state.loading ?
                                        (<ImageContainer data={sliderdata} lazyloading={false} arrows={false}
                                                         alignment={'left'} placeholder={true}/>) :
                                        (<ImageContainer data={sliderdata} lazyloading={false} arrows={false}
                                                         alignment={'left'} placeholder={false}/>)
                                    }
                                </div>
                            </div>
                            <div className={'col-12'}>
                                <h1 className={'pt-4 pt-lg-5 text-white text-uppercase fz-140 pb-0 mb-1'}>Eisfabrik</h1>
                            </div>
                            <div className={'col-md-5 d-flex align-items-start flex-column pb-4 pe-md-5'}>
                                <div className={'align-self-start '}>
                                    <p className={'text-white text-uppercase fz-45 has-secondary-font fw-normal'}>Kunst
                                        Theater Tanz</p>
                                    <p className={'lead text-white'}>Die EISFABRIK ist ein Zentrum der Künste – Theater, Tanz, Musik, bildende Kunst und Fotografie.</p>
                                </div>
                                <ScrollIntoView selector="#program"
                                                className={'d-block align-self-center mt-lg-5 pt-lg-4 pb-5 mb-auto mt-auto w-100 d-grid gap-2'}>
                                    <button
                                        className={'btn btn-lg btn-dark border-light border-3 text-uppercase has-secondary-font fw-bold'}>zum
                                        Programm
                                    </button>
                                </ScrollIntoView>
                                <div className={'align-self-end mt-auto parner-logos w-100 d-none d-lg-flex'}>
                                    <div className={'row'}>
                                        <PartnerLogos/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'container parner-logos d-flex d-lg-none pb-4'}>
                        <div className={'row  justify-content-center'}>
                            <PartnerLogos/>
                        </div>
                    </div>
                </div>
                <section id={'program'}>
                    <article className={'container'}>
                        <h1 className={'h2 pb-5 text-uppercase section-heading'}>Programm</h1>
                        {this.state.eventlist.map((event, index) => (
                            <div key={'event-' + index} className={'row border-top border-3 border-dark pt-4 pb-4'}>
                                <div className={'col-10'}>
                                    <div className={'row'}>
                                        <div className={' col-lg-2 pb-2'}>
                                            <span
                                                className={'d-lg-block text-uppercase  has-secondary-font fw-bold fz-24'}>{event.weekday} {event.date_short} </span>
                                            <span
                                                className={'d-lg-block has-secondary-font fw-bold fz-24 '}>{this.renderTime(event.date_time)}</span>
                                            <span
                                                className={'d-block text-muted text-uppercase small'}> {event.genre}</span>
                                        </div>
                                        <div className={'col-lg-10'}>
                                            <h2 className={'h3 fz-24'}>
                                                {this.checkInternalLink(event.link)===false ? (
                                                    <a className={'title-link'} href={event.link}>{event.title} – {event.subtitle}</a>
                                                ) : ( <NavLink
                                                        className={"title-link"}
                                                        to={event.link}
                                                    >
                                                        {event.title} – {event.subtitle}
                                                    </NavLink>
                                                )
                                                }
                                            </h2>
                                            <p className={'text-muted mb-0 line-clamp-2'}>{event.intro} …</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={'col-2 d-flex align-items-center'}>
                                    {this.checkInternalLink(event.link) === false ? (
                                        <a href={event.link}
                                           className={`d-flex list-link-read-more ${event.link ? "extern" : ""}`}
                                           title={event.title + ' anzeigen'}><span
                                            className={'d-none'}>{event.title}</span></a>
                                    ) : (
                                        <NavLink
                                            className={`d-flex list-link-read-more ${event.link ? "extern" : ""}`}
                                            to={event.link}
                                            title={event.title + ' anzeigen'}
                                        >
                                            <span className={'d-none'}>{event.title}</span>
                                        </NavLink>
                                    )
                                    }
                                </div>
                            </div>
                        ))}
                        {this.renderShowmore(this.state.current_chunks < (this.state.total_chunks - 1))}
                    </article>
                </section>
                {(this.state.loading === false && this.state.highlights.length>0) &&
                    <section>
                        <div className={'container pb-5 mb-5'}>
                            <h1 className={'h2 pb-5 text-uppercase section-heading'}>Highlights</h1>
                            <Highlights highlights={this.state.highlights}/>
                        </div>
                    </section>
                }
            </div>
        );
    }
}

export default HomeDefault;

