import React, {Component} from 'react';
import SectionsPage from "../../components/SectionsPage";

class Kontakt extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {
    }


    render() {
        return (
            <>
                <SectionsPage pagetype={'normal'} _id={'f87b7be2343635517000014e'} />
            </>
        );
    }
}

export default Kontakt;

