import React, { useState } from "react"
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
import RenderImage from '../Image/RenderImage'
import Modal from 'react-overlays/Modal';
import styled from 'styled-components'

// import 'lazysizes';
// import 'lazysizes/plugins/attrchange/ls.attrchange';
const Backdrop = styled("div")`
  position: fixed;
  z-index: 1040;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.5;
`;
const RandomlyPositionedModal = styled(Modal)`
  position: fixed;
  width: auto;
  z-index: 1040;
  border: 1px solid #e5e5e5;
  background-color: white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  padding: 20px;
  overflow-y:auto;
`;
function Arrow(props) {
    const disabeld = props.disabled ? " arrow--disabled" : ""
    return (
        <svg
            onClick={props.onClick}
            className={`arrow ${
                props.left ? "arrow--left" : "arrow--right"
            } ${disabeld}`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
        >
            {props.left && (
                <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
            )}
            {!props.left && (
                <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
            )}
        </svg>
    )
}
function Video(prop){
    return (
        <div className="ratio ratio-16x9">
        <iframe width="560" height="315"
                    src={prop.videourl+'&autoplay=1'}
                    title="YouTube video player" frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen/>
        </div>
    );
}
export default function ImageSlider(prop) {
    // console.log(prop);
    const [loaded, setLoaded] = React.useState([])
    const [currentSlide, setCurrentSlide] = React.useState(0)
    const [show, setShow] = useState(false);
    const renderBackdrop = (props) => <Backdrop {...props} />;
    // const [opacities, setOpacities] = React.useState([])
    let timeout
    let mouseOver = false
    const [sliderRef, instanceRef] = useKeenSlider({
        initial: 0,
        loop: true,
        // mode: "free-snap",
        slides: {
            // origin: "center",
            perView: prop.perView[0],
            spacing: prop.spacing
        },
        slideChanged(slider) {
            // const new_opacities = slider.track.details.slides.map((slide) => slide.portion)
            // setOpacities(new_opacities)
            setCurrentSlide(slider.track.details.rel)
        },

        clearNextTimeout() {
        clearTimeout(timeout)
        },
        nextTimeout(slider) {
        clearTimeout(timeout)
        if (mouseOver) return
        timeout = setTimeout(() => {
            slider.next()
        }, 2000)
        },
        created() {
            setLoaded(true)
        },
    })
    React.useEffect(() => {
        const new_loaded = [...loaded]
        new_loaded[currentSlide] = true
        setLoaded(new_loaded)
    }, [currentSlide])

    return (
        <>
            <div className="image-slider navigation-wrapper ms-0 me-0">
                <div ref={sliderRef} className="keen-slider">
                    {prop.imagelist?.map((entry, idx) => (
                        <div
                            key={idx}
                            className={"keen-slider__slide ratio ratio-1x1 number-slide"+idx }
                        >
                                <div><div className={'position-relative'}>
                                    <RenderImage
                                    path={entry.path}
                                    image={ process.env.REACT_APP_API_URL + '/api/cockpit/image?token='+ process.env.REACT_APP_API_TOKEN +'&src='+entry.path+'&m=Thumbnail&h=550&w=550&o=true&mime=image/webp'}
                                    placeholder={entry.styles[3].path}
                                    alt={entry.meta.title}
                                    caption={entry.meta.caption}
                                    lazyloading={prop.lazyloading}
                                    width={550}
                                    height={550}
                                    />
                                    <React.Fragment>
                                        <div className={'img-copyright'}>
                                        {entry.meta.caption!=null &&
                                            <span aria-hidden="true">{entry.meta.caption}<br/></span>
                                        }
                                        {entry.meta.copyright!=null &&
                                            <span aria-hidden="true">Foto: {entry.meta.copyright}</span>
                                        }
                                        </div>
                                    </React.Fragment>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {prop.imagelist.length > 1 && prop.arrows && loaded && instanceRef.current && (
                    <>
                        <Arrow
                            left
                            onClick={(e) =>
                                e.stopPropagation() || instanceRef.current?.prev()
                            }
                            disabled={currentSlide === 0}
                        />

                        <Arrow
                            onClick={(e) =>
                                e.stopPropagation() || instanceRef.current?.next()
                            }
                            disabled={
                                currentSlide ===
                                instanceRef.current.track.details.slides.length - 1
                            }
                        />
                    </>
                )}
                {prop.video!==false &&
                    <>
                        <div className={'videobutton'}>
                            <div className={'row align-items-center h-100 text-center'}>
                                <div className={'col-6 offset-3 mt-auto mb-auto videoplay'}>
                                    <button onClick={() => setShow(true)} className={'btn btn-lg btn-dark border-light border-3 text-uppercase has-secondary-font fw-bold mt-4'}>Play</button>
                                    <span className={'d-block mt-2 text-white small'}>Mit dem Abspielen des Videos akzeptieren Sie die Datenschutzerklärung von YouTube.<br/>
                                        <a className={'text-white'} href={"/datenschutz"}>Mehr erfahren</a>
                                        </span>
                                </div>
                            </div>
                            <RandomlyPositionedModal
                                show={show}
                                onHide={() => setShow(false)}
                                renderBackdrop={renderBackdrop}
                                aria-labelledby="modal-label"
                                className={'video-modal'}
                            >
                                <div>
                                    <div className={'container-fluid'}>
                                        <div className={'row'}>
                                            <div className={'col-12 text-end'}>
                                                <button
                                                    type="button"
                                                    className="btn btn-lg btn-dark mb-4"
                                                    onClick={() => setShow(false)}
                                                >
                                                    <img src={'/static/images/close_white_24dp.svg'} alt={"close"}/>
                                                    <span className={'d-none'}>Schließen</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'container h-80'}>
                                        <div className={'row'}>
                                             <div className={'col col-lg-10 offset-lg-1 text-align-center'}>
                                                 <Video videourl={prop.video}/>
                                             </div>
                                        </div>
                                    </div>
                                </div>
                            </RandomlyPositionedModal>
                        </div>
                    </>
                }
            </div>
            {prop.imagelist.length > 1 && prop.dots && loaded && instanceRef.current && (
                <div className="dots">
                    {[
                        ...Array(instanceRef.current.track.details.slides.length).keys(),
                    ].map((idx) => {
                        return (
                            <button
                                key={idx}
                                onClick={() => {
                                    instanceRef.current?.moveToIdx(idx)
                                }}
                                className={"dot" + (currentSlide === idx ? " active" : "")}
                            />
                        )
                    })}
                </div>
            )}
        </>
    )
}


