import React, { useState, useEffect } from 'react';
// import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
// import ImageContainer from "../../components/Image/ImageContainer";
// import TextContainer from "../../components/Text/TextContainer";
import {LazyLoadImage} from "react-lazy-load-image-component";
import {getCollectionUrl} from "../../utils";
import WeisseHalleText from "../../components/Text/WeisseHalleText";

const WeisseHalle = _ => {
    let params = useParams();
    // const slug_id = params.id.split(":");
    // const _id = slug_id[1];
    const _id = params.id;
    const [data, setData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const response = await fetch(getCollectionUrl('events'), {
                    method: 'post',
                    mode: 'cors',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        filter: {_id:_id,genre:'Kunst'},
                        sort: {'startdate': -1},
                        populate: 1, // resolve linked collection items
                    })
                })
                const json = await response.json();
                setData(json.entries[0]);
                setIsLoading(false);
            } catch (error) {
                console.log("error", error);
            }
        };
        fetchData();

    }, [_id]);
    function renderImage(data,width=550,height=550){
        return <React.Fragment>
            <LazyLoadImage
                className={'img-fluid w-auto h-auto'}
                alt={data.alt}
                height={height}
                width={550}
                wrapperClassName={'d-lg-flex w-100 h-auto '}
                src={data.image} // use normal <img> attributes as props
                placeholderSrc={data.placeholder}
                effect="blur"
            />
        </React.Fragment>
    }
    return   <div>
        <div className={'bg-dark text-white'}>
            <div className={'container pt-lg-5'}>
                <div className={'row'}>
                    <div className={'col-lg-12'}>
                        <h1 className="pt-4 pt-lg-4 text-uppercase fz-80 pb-0 mb-1">
                            Kunst in der
                        {(isLoading === false) &&
                            <>
                                    {(data.withoutwhitehall !== true) &&
                                        <> Weissen Halle</>
                                }
                                    {(data.withoutwhitehall === true) &&
                                        <> EISFABRIK</>
                                    }

                            </>
                        }
                        </h1>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'order-md-last col-md-6 col-xl-5 ps-0 pe-0 pb-5'}>
                        <div className="ratio ratio-1x1">
                            {(isLoading === false && data.image!==null)  &&
                                <React.Fragment>
                                    {renderImage({
                                        image: process.env.REACT_APP_API_URL + '/api/cockpit/image?token='+ process.env.REACT_APP_API_TOKEN +'&src='+data.image.path+'&m=Thumbnail&h=550&w=550&o=true&mime=image/webp',
                                        placeholder:data.image.styles[3].path,
                                        alt:'',
                                    })}
                                </React.Fragment>
                            }
                        </div>
                    </div>
                    <div className=" pe-md-5  col-md-6  d-flex align-items-start flex-column pt-3 pb-5 hyphenation">
                        <p className="mt-auto mb-auto h1 text-uppercase">{data.title}<br />{data.subtitle}<br/></p></div>
                </div>
            </div>
        </div>
        <div className={'container pt-5'}>
        <div className={'row'}>
            <div className={'col-lg-6'}>
                {/*<p>{data.title}</p>*/}
                {/*<p>{data.subtitle}</p>*/}
                <div className={'lead hyphenation'} dangerouslySetInnerHTML={{__html: data.description}}></div>
            </div>
            <div className={'col-lg-6 pb-5'}>
                <div className={'lead'} dangerouslySetInnerHTML={{__html: data.openinghours}}></div>
                {(isLoading === false && data.attachement!==null)  &&
                    <React.Fragment>
                        <a className={'btn btn-dark has-secondary-font fw-bold text-uppercase'} href={process.env.REACT_APP_API_URL + '/storage/uploads/' + data.attachement.path}>Download-Einladung</a>
                    </React.Fragment>
                }
            </div>
        </div>
    </div>
        {(isLoading === false) &&
            <>
                {(data.withoutwhitehall !== true) &&
                    <WeisseHalleText/>
                }
            </>
        }
    </div>
};

export default WeisseHalle;

