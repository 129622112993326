import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Mainnavigation from "../../components/Mainnavigation";
import Footer from "../../components/Footer";
import {NavLink} from "react-router-dom";
import MemberList from "../../components/MemberList";


class Artists extends Component {
    render() {
        return (
            <div className="">
                <Helmet>
                    <title>Eisfabrik Hannover – Künstler*innen</title>
                </Helmet>
                {/* End Page SEO Content */}
                <div className={'container pt-5 '}>
                    <div className={'row'}>
                        <div className={'col-12 pt-4 pt-lg-4 '}>
                            <MemberList
                                collection={'members'}
                                type={'artists'}
                                head={'Künstler*innen'}
                                subhead={'in der EISFABRIK'}
                                teaser={'Die EISFABRIK ist ebenso Spiel- und Präsentationsstätte wie Lebens- und Arbeitsraum. – Tanz, Theater, Musik, Fotografie und Künste haben hier in den den vier großen Hallen und Sälen, den Ateliers, Übungsräumen und Musikstudios ihren Ort.'}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Artists;

