import React from 'react';
import {NavLink} from "react-router-dom";
import PartnerLogos from "./Image/PartnerLogos";
const FooterNav=[
    {
        title:'Über die EISFABRIK',
        link:'/eisfabrik-hannover'
    },
    {
        title:'Geschichte der EISFABRIK',
        link:'/eisfabrik-geschichte'
    },
    {
        title:'Kontakt & Anreise',
        link:'/kontakt'
    }
]
const Footer = () => {
    return (
        <footer className={'bg-dark pt-5 text-white border-top border-3 border-light'}>
            <div className={'container'}>
                <div className={'row'}>
                    <div className={'col-lg-4 pb-5 mb-5'}>
                        <h3 className={'h5 text-uppercase mb-4 mb-lg-0'}>EISFABRIK</h3>
                        <p className={'pe-3'}>Die EISFABRIK ist seit Ende der 1970er Jahre ein Zentrum der Künste.</p>
                        <div className={'row'}>
                            <PartnerLogos />
                        </div>
                    </div>
                    <div className={'col-lg-4 pb-5'}>
                        <h3 className={'h5 text-uppercase'}>Informationen</h3>
                        <ul className="nav flex-column">
                            {FooterNav?.map((entry, idx) => (
                                    <li key={idx} className="nav-item">
                                        <NavLink
                                            className={"nav-link ps-0"}
                                            to={entry.link}
                                        >
                                            {entry.title}
                                        </NavLink>
                                    </li>
                            ))}
                        </ul>
                        <div className="d-block d-lg-none pt-2 mb-5 mb-lg-0">
                            <a href="https://www.facebook.com/eisfabrikhannover" className={'btn btn-link ps-0 me-2'}><img alt={'Facebook'} width={'24'} height={'24'} className={'img-fluid align-self-center'} src={'/static/images/facebook_white.svg'} /></a>
                            <a href="https://www.instagram.com/eisfabrik_kultur/?hl=de"className={'btn btn-link'}><img alt={'Instagram'} width={'24'} height={'24'} className={'img-fluid align-self-center'} src={'/static/images/instagram_white.svg'} /></a>
                        </div>
                    </div>
                    <div className={'col-lg-4  pb-5'}>
                        <h3 className={'h5 text-uppercase mb-4'}>Gefördert von</h3>
                        <img width={'250'} height={'75'} className={'img-fluid mb-3 d-block'} src={'/static/images/lhh_250.webp'} alt={'Kulturbüro der Landeshauptstadt Hannover (Logo)'}/>
                        <img width={'250'} height={'46'} className={'img-fluid d-block'} src={'/static/images/mwk.svg'} alt={'Nds. Ministerium für Wissenschaft und Kultur (Logo)'}/>
                    </div>
                </div>
                <div className={'row pt-3 pb-3'}>
                    <div className={'col-12'}>
                        <ul className="nav">
                            <li className="nav-item">
                                <NavLink
                                    className={"nav-link ps-0"}
                                    to={'/datenschutz'}
                                >
                                    Datenschutz
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink
                                    className={"nav-link ps-0"}
                                    to={'/impressum'}
                                >
                                    Impressum
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;