import React, {useEffect} from "react";
import {
    Routes,
    Route,
} from "react-router-dom";
import {Helmet} from "react-helmet";
import AOS from "aos";
import "aos/dist/aos.css";
import Mainnavigation from "./components/Mainnavigation";
import Footer from "./components/Footer";
import Home from "./views/home/HomeDefault";
import Eisfabrik from "./views/innerpages/Eisfabrik";
import Geschichte from "./views/innerpages/Geschichte";
import Team from "./views/innerpages/Team";
import Freundeskreis from "./views/innerpages/Freundeskreis";
import WeisseHalle from "./views/innerpages/WeisseHalle";
import WeisseHalleItem from "./views/innerpages/WeisseHalle";
import Kontakt from "./views/innerpages/Kontakt";
import Impressum from "./views/innerpages/Impressum";
import Datenschutz from "./views/innerpages/Datenschutz";
import NotFound from "./views/innerpages/NotFound";
import ScrollToTop from "./components/ScrollToTop";
import ScrollTopBehaviour from "./components/ScrollTopBehaviour";
import Artists from "./views/innerpages/Artists";

const App = () => {
    useEffect(() => {
        AOS.init({
            duration: 1200,
        });
    }, []);

    return (
        <>
            <Helmet>
                <title>Eisfabrik | Kultur & Theater in Hannover</title>
                <meta name="description" content="Kunst- und Kulturzentrum in der Südstadt in Hannover."/>
                <meta
                    name="keywords"
                    content="Hannover, Südstadt, Veranstaltungszentrum, Veranstaltungen, Künstler, Kunst, Theater, Tanz, Musik, Fotografie, GaF Hannover, Commedia Futura, Kunst in der Weissen Halle"
                />
            </Helmet>
            <Mainnavigation/>
            <main>
                <ScrollTopBehaviour/>
                <Routes>
                    <Route path="/" element={<Home/>} animate={true}/>
                    <Route path="/eisfabrik-hannover" element={<Eisfabrik />} animate={true}/>
                    <Route path="/eisfabrik-kuenstlerinnen" element={<Artists />} animate={true}/>
                    <Route path="/eisfabrik-geschichte" element={<Geschichte/>} animate={true}/>
                    <Route path="/eisfabrik-team" element={<Team/>} animate={true}/>
                    <Route path="/freundeskreis" element={<Freundeskreis/>} animate={true}/>
                    <Route path="/kunst-in-der-weissen-halle" element={<WeisseHalle/>} animate={true}/>
                    <Route path="/kunst-in-der-weissen-halle/:id" element={<WeisseHalleItem/>} animate={true}/>
                    <Route path="/events/:id" element={<WeisseHalleItem/>} animate={true}/>
                    <Route path="/highlights" element={<Home/>} animate={true}/>
                    <Route path="/highlights/:id" element={<Home/>} animate={true}/>
                    <Route path="/impressum" element={<Impressum/>} animate={true}/>
                    <Route path="/datenschutz" element={<Datenschutz/>} animate={true}/>
                    <Route path="/kontakt" element={<Kontakt/>} animate={true}/>
                    <Route
                        path="*"
                        element={<NotFound/>}
                    />
                </Routes>
            </main>
            <Footer/>
            <ScrollToTop/>
        </>
    );
}

export default App;

