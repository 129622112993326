import React, {Component} from 'react';
import SectionsPage from "../../components/SectionsPage";

class Datenschutz extends Component {
    render() {
        return (
            <>
                <SectionsPage pagetype={'normal'} _id={'6f78879a3663632f750000ea'} />
            </>
        );
    }
}

export default Datenschutz;

