import React, { Component } from 'react';

class WeisseHalleText extends Component {
    constructor(props){
        super(props);
        this.state = {
        }
    }
    render() {
        return (
            <div className={'container pt-3 pb-5'}>
                <div className={'row'}>
                    <div className={'col-12'}>
                        <p className={'lead  hyphenation border-top border-bottom border-dark border-3 pt-4 pb-4'}>Unter dem Label „Kunst in der Weissen Halle“ hat der EISFABRIK e.V. nach der Sanierung des Raumes 2008 ein eigenes Ausstellungsprogramm begonnen. In der WEISSEN HALLE gibt es Ausstellungen mit Künstlern der Region, seit 2012 kuratiert von Dagmar Brand (Verein zur Förderung und Durchführung von Kunstprojekten in Hannover). Kunst in der Weissen Halle wird gefördert durch die Region Hannover.</p>
                    </div>
                </div>
            </div>
        )}
}
export default WeisseHalleText;